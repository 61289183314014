<template>
  <div class="datetime">
    <div class="datetime__top"></div>
    <div class="datetime__bottom"></div>
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss">
.datetime {
}
</style>
